const data = [
  {
    text: "O nas",
    slug: "#",
  },
  {
    text: "Korzyści",
    slug: "#",
  },
  {
    text: "Nasze Produkty",
    slug: "products",
  },
  {
    text: "Grupa dla inwestorów",
    slug: "#",
  },
  {
    text: "Locked Trade Discord",
    slug: "#",
  },
];

export default data;
